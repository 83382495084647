.CSVForm {
  margin: 20px auto;
  max-width: 600px;
  text-align: left;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #6c757d;
}

button:hover:enabled {
  background-color: #0056b3;
}

.help-text {
  font-size: 0.9rem;
  color: #666;
}
